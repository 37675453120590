/* tslint:disable */
/* eslint-disable */
// @ts-ignore
import * as Types from "types/global-graphql-types"

import { DocumentNode } from "graphql"
import * as Apollo from "@apollo/client"
import * as ApolloReactHooks from "common/utils/extendApolloHooks"
const defaultOptions = {} as const
export type CurrentEmployeeQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type CurrentEmployeeQuery = {
  __typename: "Query"
  currentEmployee: {
    __typename: "Employee"
    id: number
    name: string
    authenticationToken: string | null
    email: string | null
    tawkHash: string | null
    roles: Array<Types.EmployeeRole>
    restaurant: {
      __typename: "Restaurant"
      id: number
      name: string
      allowOrders: boolean | null
    } | null
  } | null
}

export const CurrentEmployeeQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "currentEmployeeQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentEmployee" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authenticationToken" }
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "tawkHash" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "restaurant" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "allowOrders" }
                      }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "roles" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown) as DocumentNode

/**
 * __useCurrentEmployeeQuery__
 *
 * To run a query within a React component, call `useCurrentEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentEmployeeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentEmployeeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentEmployeeQuery,
    CurrentEmployeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    CurrentEmployeeQuery,
    CurrentEmployeeQueryVariables
  >(CurrentEmployeeQueryDocument, options)
}
export function useCurrentEmployeeQueryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentEmployeeQuery,
    CurrentEmployeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    CurrentEmployeeQuery,
    CurrentEmployeeQueryVariables
  >(CurrentEmployeeQueryDocument, options)
}
export type CurrentEmployeeQueryHookResult = ReturnType<
  typeof useCurrentEmployeeQuery
>
export type CurrentEmployeeQueryLazyQueryHookResult = ReturnType<
  typeof useCurrentEmployeeQueryLazyQuery
>
export type CurrentEmployeeQueryQueryResult = Apollo.QueryResult<
  CurrentEmployeeQuery,
  CurrentEmployeeQueryVariables
>
