export const ROLE_OPTIONS = [
  {
    value: "full",
    label: "Admin (Full Access)"
  },
  {
    value: "booking",
    label: "Booking Events"
  },
  {
    value: "orders",
    label: "Shift Management (order-ahead)"
  },
  {
    value: "menu",
    label: "Menu Management"
  },
  {
    value: "reports",
    label: "Reports"
  }
]

export const hasFullAccess = (user) => user && user.roles.includes("full")

export const hasBookingAccess = (user) =>
  user && (user.roles.includes("full") || user.roles.includes("booking"))

export const hasMenuAccess = (user) =>
  user && (user.roles.includes("full") || user.roles.includes("menu"))

export const hasReportsAccess = (user) =>
  user && (user.roles.includes("full") || user.roles.includes("reports"))

export const hasPermitsAccess = (user) =>
  user && (user.roles.includes("full") || user.roles.includes("permits"))

export const hasOrdersAccess = (user) =>
  user && (user.roles.includes("full") || user.roles.includes("orders"))
