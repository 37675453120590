/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import Rollbar from "common/utils/Rollbar"

Rollbar.init()

// Import all the third party stuff
import React from "react"
import "sanitize.css/sanitize.css"
import "common/i18n"

// eslint-disable-next-line
import formActionSaga from "common/pkg/redux-form-saga"
import confirmDialog from "trucks/utils/confirmDialog"
import requestValue from "trucks/utils/requestValue"

// Import root app
import App from "trucks/containers/App"

// Import CSS reset and Global Styles
import theme, { muiTheme, jss } from "./theme"

import ConnectedApp from "common/ConnectedApp"
import DashboardTypeContext from "common/DashboardTypeContext"
import logEventConfig from "trucks/utils/logEventConfig"
import configureStore from "./configureStore"

export default function TrucksApp() {
  return (
    <ConnectedApp
      authTokenName={"X-Employee-Token"}
      authEmailName={"X-Employee-Email"}
      jss={jss}
      theme={theme}
      muiTheme={muiTheme}
      logEventConfig={logEventConfig}
      configureStore={configureStore}
      confirmDialog={confirmDialog}
      requestValue={requestValue}
    >
      <DashboardTypeContext.Provider value={"trucks"}>
        <App />
      </DashboardTypeContext.Provider>
    </ConnectedApp>
  )
}
