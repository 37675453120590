import * as React from "react"
import { NavLink } from "react-router-dom"

const ForwardNavLink = React.forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} />
))

ForwardNavLink.displayName = "ForwardNavLink"

export default ForwardNavLink
