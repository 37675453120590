import React from "react"
import MobileScreenShareIcon from "@material-ui/icons/MobileScreenShare"
import styled from "styled-components"
import Button from "@material-ui/core/Button"
import appUrl from "./appUrl"

const MobileIcon = styled(MobileScreenShareIcon)``

export default class TruckAppButton extends React.Component {
  render() {
    const url = appUrl()
    if (!url) {
      return null
    }
    if (window.ReactNativeWebView) {
      return (
        <Button
          variant="text"
          color="inherit"
          onClick={() => {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: "back"
              })
            )
          }}
          style={{ minWidth: 48 }}
        >
          <MobileIcon />
        </Button>
      )
    }
    return (
      <Button
        variant="text"
        color="inherit"
        component="a"
        href={url}
        style={{ minWidth: 48 }}
      >
        <MobileIcon />
      </Button>
    )
  }
}
