import RestaurantIcon from "@material-ui/icons/Restaurant"
import MonitoringIcon from "@material-ui/icons/ViewList"
import React from "react"
import HomeIcon from "@material-ui/icons/Home"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import RestaurantMenu from "@material-ui/icons/RestaurantMenu"
import SettingsIcon from "@material-ui/icons/Settings"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import CalendarIcon from "@material-ui/icons/CalendarTodayRounded"
import appUrl from "trucks/components/Navbar/TruckAppButton/appUrl"
import ReportsIcon from "@material-ui/icons/Assessment"
import MoneyIcon from "@material-ui/icons/CreditCard"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import MarketplaceIcon from "@material-ui/icons/Store"
import TruckIcon from "@material-ui/icons/LocalShipping"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

import {
  hasBookingAccess,
  hasFullAccess,
  hasOrdersAccess,
  hasMenuAccess,
  hasReportsAccess,
  hasPermitsAccess
} from "trucks/constants/employeeRoles"
import ShiftSalesIcon from "@material-ui/icons/MonetizationOn"
import ChatIcon from "@material-ui/icons/Chat"
import { Query } from "@apollo/client/react/components"
import { UnreadChatsQueryDocument } from "./types/UnreadChatsQuery"

const SIDEBAR_ITEMS = [
  {
    name: "Go Back",
    action: "goBackToApp",
    noSession: true,
    link: "/",
    matches: [],
    showIf: () => !!window.ReactNativeWebView,
    icon: <ArrowBackIcon />
  },
  {
    name: "Log In",
    link: "/login",
    noSession: true,
    matches: [/^\/login$/],
    icon: <AccountBoxIcon />
  },
  {
    name: "Sign Up",
    link: "/signup/truck",
    noSession: true,
    matches: [/^\/signup\/truck$/],
    icon: <AccountCircleIcon />
  },
  {
    name: "Home",
    link: "/",
    matches: [/^\/$/],
    icon: <HomeIcon />
  },
  {
    name: "Calendar",
    link: "/calendar",
    matches: [/^\/calendar.*/],
    showIf: ({ user }) => hasBookingAccess(user) || hasPermitsAccess(user),
    icon: <CalendarIcon />
  },
  {
    name: "Order Monitoring",
    link: "/order-monitoring",
    matches: [/^\/order-monitoring$/],
    showIf: ({ user }) => hasOrdersAccess(user) || hasReportsAccess(user),
    icon: <MonitoringIcon />
  },
  {
    name: "Catering",
    link: "/catering-requests",
    matches: [/^\/catering-requests$/],
    showIf: ({ user }) => hasBookingAccess(user),
    icon: <RestaurantIcon />
  },
  {
    name: "Chats",
    link: "/chat-conversations",
    matches: [/^\/chat-conversations.*$/],
    badgeContent: (children) => (
      <Query query={UnreadChatsQueryDocument} fetchPolicy="cache-first">
        {({ data }) => {
          const total = data?.truckChats?.total

          if (!total) return children(null)

          return children(total)
        }}
      </Query>
    ),
    icon: <ChatIcon />
  },
  {
    name: "Reports",
    link: "/reports",
    matches: [/^\/reports.*/],
    showIf: ({ user }) => hasReportsAccess(user),
    icon: <ReportsIcon />
  },
  {
    name: "Menu",
    link: "/menus",
    matches: [/^\/menus.*/],
    showIf: ({ user }) => hasMenuAccess(user),
    icon: <RestaurantMenu />
  },
  {
    name: "Shift Sales",
    link: "/shift-sales",
    matches: [/^\/shift-sales$/],
    showIf: ({ user }) => hasBookingAccess(user),
    icon: <ShiftSalesIcon />
  },
  // {
  //   name: "Payout / Payment",
  //   link: "/payment",
  //   matches: [/^\/payment.*/],
  //   icon: <PaymentIcon />
  // },
  {
    name: "Payout / Payment",
    link: "/payment-settings",
    matches: [/^\/payment-settings.*/, /^\/business-information.*/],
    showIf: ({ user }) => hasFullAccess(user),
    icon: <MoneyIcon />
  },
  {
    name: "Truck Rewards",
    link: "/truck-rewards",
    matches: [/^\/truck-rewards.*/],
    showIf: ({ user }) => hasFullAccess(user),
    icon: <ShiftSalesIcon />
  },
  {
    name: "Settings",
    link: "/settings",
    matches: [/^\/settings.*/, /^\/users.*/],
    icon: <SettingsIcon />
  },
  {
    name: "My Trucks",
    link: "/trucks",
    matches: [/^\/trucks.*/],
    icon: <TruckIcon />
  },
  {
    name: "Marketplace",
    link: "/marketplace",
    matches: [/^\/marketplace.*/],
    icon: <MarketplaceIcon />
  },
  {
    name: "Log Out",
    action: "logout",
    showIf: appUrl,
    link: "/",
    matches: [],
    icon: <ExitToAppIcon />
  }
]

export default SIDEBAR_ITEMS
