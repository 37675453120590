import { graphql } from "@apollo/client/react/hoc"
import { CurrentEmployeeQueryDocument } from "./types/currentEmployeeQuery"

const withUser = graphql(CurrentEmployeeQueryDocument, {
  options: () => ({
    fetchPolicy: "cache"
  }),
  props: ({ data: { loading, currentEmployee } }) => ({
    userLoading: loading,
    user: currentEmployee
  })
})

export default withUser
