import React from "react"
import hoistNonReactStatics from "hoist-non-react-statics"

export const UserContext = React.createContext(null)

const withExistingUser = (WrappedComponent) => {
  const klass = class extends React.Component {
    static contextType = UserContext

    static displayName = `withExistingUser(${
      WrappedComponent.displayName || WrappedComponent.name || "Component"
    })`

    render() {
      return <WrappedComponent {...this.props} user={this.context} />
    }
  }

  hoistNonReactStatics(klass, WrappedComponent)
  return klass
}

export default withExistingUser
