/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux-immutable"
import { connectRouter } from "connected-react-router/immutable"
import { reducer as formReducer } from "redux-form/immutable"
import authReducer from "./auth/reducer"
import history from "common/utils/history"

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth: authReducer,
    ...injectedReducers
  })
}
