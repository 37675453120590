import config from "common/config"

function iOS() {
  return (
    ["iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") &&
      "ontouchend" in document &&
      !window.MSStream)
  )
}

const appUrl = (options = {}) => {
  const actualOptions = {
    force: false,
    to: "home",
    ...options
  }
  const { force, to } = actualOptions
  const isAndroid = /(android)/i.test(navigator.userAgent)
  const isIOS = iOS()
  let url = `${config.webHost}/trucks/app/link/${to}`
  if (!isAndroid && !isIOS) {
    if (force) {
      return url
    }
    return null
  }
  if (isAndroid && to === "home") {
    url = "intent://scan/#Intent;scheme=bft;package=com.bft;end"
  }
  return url
}

export default appUrl
