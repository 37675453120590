/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from "react"
import PropTypes from "prop-types"
import { Switch, Route, withRouter, Redirect } from "react-router-dom"
import CssBaseline from "@material-ui/core/CssBaseline"
import { connect } from "react-redux"
import { compose } from "redux"
import CateringRequestPage from "trucks/containers/CateringRequestPage/Loadable"
import HealthPermitsPage from "trucks/containers/HealthPermitsPage/Loadable"
import HealthPermitNewPage from "trucks/containers/HealthPermitNewPage/Loadable"
import TruckPermitNewPage from "trucks/containers/TruckPermitNewPage/Loadable"
import HealthPermitEditPage from "trucks/containers/HealthPermitEditPage/Loadable"
import TruckPermitEditPage from "trucks/containers/TruckPermitEditPage/Loadable"
import CateringRequestsPage from "trucks/containers/CateringRequestsPage/Loadable"
import CateringRequestOrdersPage from "trucks/containers/CateringRequestOrdersPage/Loadable"
import LocationStatsPage from "trucks/containers/LocationStatsPage/Loadable"
import HomePage from "trucks/containers/HomePage/Loadable"
import GoogleCalendarPage from "trucks/containers/GoogleCalendarPage/Loadable"
import FacebookPostingPage from "trucks/containers/FacebookPostingPage/Loadable"
import NewMenuPage from "trucks/containers/NewMenuPage/Loadable"
import MenusPage from "trucks/containers/MenusPage/Loadable"
import NotFoundPage from "trucks/containers/NotFoundPage/Loadable"
import LoginPage from "trucks/containers/LoginPage/Loadable"
import OrderMonitoringPage from "trucks/containers/OrderMonitoringPage/Loadable"
import SignUpTruckPage from "trucks/containers/SignUpTruckPage/Loadable"
import ClaimTruckPage from "trucks/containers/ClaimTruckPage/Loadable"
import SignUpBusinessInfoPage from "trucks/containers/SignUpBusinessInfoPage/Loadable"
import SignUpPaymentPage from "trucks/containers/SignUpPaymentPage/Loadable"
import SignUpPayoutPage from "trucks/containers/SignUpPayoutPage/Loadable"
import SignUpReportingTruckPage from "trucks/containers/SignUpReportingTruckPage/Loadable"
import SignUpPermitsTruckPage from "trucks/containers/SignUpPermitsTruckPage/Loadable"
import SignUpScheduleTruckPage from "trucks/containers/SignUpScheduleTruckPage/Loadable"
import ErrorBoundary from "common/components/ErrorBoundary/index"
import Navbar from "trucks/components/Navbar"
import NotificationStack from "trucks/components/NotificationStack"
import BlockUIConnected from "trucks/components/BlockUIConnected"
import withUser from "trucks/queries/withUser"
import queryString from "query-string"
import PageLoader from "common/components/PageLoader"
import { UserContext } from "trucks/components/withExistingUser"
import MenuPage from "trucks/containers/MenuPage/Loadable"
import OrderPage from "trucks/containers/OrderPage/Loadable"
import NewFoodItemPage from "trucks/containers/NewFoodItemPage/Loadable"
import FoodItemPage from "trucks/containers/FoodItemPage/Loadable"
import NewQuestionPage from "trucks/containers/NewQuestionPage/Loadable"
import QuestionPage from "trucks/containers/QuestionPage/Loadable"
import ReportsPage from "trucks/containers/ReportsPage/Loadable"
import ReportPage from "trucks/containers/ReportPage/Loadable"
import BusinessInformationPage from "trucks/containers/BusinessInformationPage/Loadable"
import FaqPage from "trucks/containers/FaqPage/Loadable"
import PaymentSettingsPage from "trucks/containers/PaymentSettingsPage/Loadable"
import PaymentsPage from "trucks/containers/PaymentsPage/Loadable"
import PayoutsPage from "trucks/containers/PayoutsPage/Loadable"
import NewPayoutPage from "trucks/containers/NewPayoutPage/Loadable"
import NewPaymentPage from "trucks/containers/NewPaymentPage/Loadable"
import LotBookingPage from "trucks/containers/LotBookingPage/Loadable"
import TruckCalendarPage from "trucks/containers/TruckCalendarPage/Loadable"
import SettingsPage from "trucks/containers/SettingsPage/Loadable"
import CompanySettingsPage from "trucks/containers/CompanySettingsPage/Loadable"
import OrderSettingsPage from "trucks/containers/OrderSettingsPage/Loadable"
import NotificationsSettingsPage from "trucks/containers/NotificationsSettingsPage/Loadable"
import DisasterReliefPage from "trucks/containers/DisasterReliefPage/Loadable"
import DeleteAccountPage from "trucks/containers/DeleteAccountPage/Loadable"
import CouponsPage from "trucks/containers/CouponsPage/Loadable"
import NewCouponPage from "trucks/containers/NewCouponPage/Loadable"
import UsersPage from "trucks/containers/UsersPage/Loadable"
import TrucksPage from "trucks/containers/TrucksPage/Loadable"
import NewTruckPage from "trucks/containers/NewTruckPage/Loadable"
import EditTruckPage from "trucks/containers/EditTruckPage/Loadable"
import NewUserPage from "trucks/containers/NewUserPage/Loadable"
import EditUserPage from "trucks/containers/EditUserPage/Loadable"
import AccountPage from "trucks/containers/AccountPage/Loadable"
import MarketplacePage from "trucks/containers/MarketplacePage/Loadable"
import ForgotPasswordPage from "trucks/containers/ForgotPasswordPage/Loadable"
import ResetPasswordPage from "trucks/containers/ResetPasswordPage/Loadable"
import OrderPartialRefundsListPage from "trucks/containers/OrderPartialRefundsListPage/Loadable"
import OrderPartialRefundPage from "trucks/containers/OrderPartialRefundPage/Loadable"
import EditCouponPage from "trucks/containers/EditCouponPage/Loadable"
import ShiftSalesPage from "trucks/containers/ShiftSalesPage/Loadable"
import TwitterPostingPage from "trucks/containers/TwitterPostingPage/Loadable"
import TruckRewardsPage from "trucks/containers/TruckRewardsPage/Loadable"
import TruckFlyerPage from "trucks/containers/TruckFlyerPage/Loadable"
import ChatConversationsPage from "trucks/containers/ChatConversationsPage/Loadable"
import ChatConversationPage from "trucks/containers/ChatConversationPage/Loadable"
import TwentyMilePage from "trucks/containers/TwentyMilePage/Loadable"
import { chatLogin } from "trucks/utils/chatBox"
import ScheduleWidgetPage from "trucks/containers/ScheduleWidgetPage/Loadable"

class App extends React.PureComponent {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.user && nextProps.user) {
      const { user } = nextProps
      chatLogin({ user })
    }
  }

  componentDidMount() {
    const { user } = this.props
    if (user) {
      chatLogin({ user })
    }
  }

  render() {
    const { userLoading, user } = this.props
    if (userLoading) return <PageLoader />
    const routes = user ? (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/shift-sales" component={ShiftSalesPage} />
        <Route exact path="/google-calendar" component={GoogleCalendarPage} />
        <Route exact path="/facebook-posting" component={FacebookPostingPage} />
        <Route exact path="/twitter-posting" component={TwitterPostingPage} />
        <Route exact path="/menus" component={MenusPage} />
        <Route exact path="/menus/new" component={NewMenuPage} />
        <Route exact path="/menus/:menuId" component={MenuPage} />
        <Route
          exact
          path="/menus/:menuId/items/new"
          component={NewFoodItemPage}
        />
        <Route
          exact
          path="/menus/:menuId/items/:itemId"
          component={FoodItemPage}
        />
        <Route
          exact
          path="/menus/:menuId/items/:itemId/questions/new"
          component={NewQuestionPage}
        />
        <Route
          exact
          path="/menus/:menuId/items/:itemId/questions/:questionId/edit"
          component={QuestionPage}
        />
        <Redirect to="/" path="/login" />
        <Route
          path="/sign-in"
          render={(props) => (
            <Redirect to={queryString.parse(props.location.search).to || "/"} />
          )}
        />
        <Redirect exact to="/signup/truck" path="/signup" />
        <Route exact path="/signup/truck" component={SignUpTruckPage} />
        <Route
          exact
          path="/signup/business-info"
          component={SignUpBusinessInfoPage}
        />
        <Route exact path="/payment-settings" component={PaymentSettingsPage} />
        <Route
          exact
          path="/payment-settings/payments"
          component={PaymentsPage}
        />
        <Route
          exact
          path="/payment-settings/payments/new"
          component={NewPaymentPage}
        />
        <Route exact path="/payment-settings/payouts" component={PayoutsPage} />
        <Route
          exact
          path="/payment-settings/payouts/new"
          component={NewPayoutPage}
        />
        <Route exact path="/signup/payment" component={SignUpPaymentPage} />
        <Route exact path="/signup/payout" component={SignUpPayoutPage} />
        <Route
          exact
          path="/signup/reporting"
          component={SignUpReportingTruckPage}
        />
        <Route exact path="/reports" component={ReportsPage} />
        <Route exact path="/reports/:reportUrl" component={ReportPage} />
        <Route exact path="/faq" component={FaqPage} />
        <Route
          exact
          path="/business-information"
          component={BusinessInformationPage}
        />
        <Route exact path="/lot-booking" component={LotBookingPage} />
        <Route exact path="/calendar" component={TruckCalendarPage} />
        <Route exact path="/settings" component={SettingsPage} />
        <Route
          exact
          path="/settings/company-settings"
          component={CompanySettingsPage}
        />
        <Route exact path="/settings/orders" component={OrderSettingsPage} />
        <Route
          exact
          path="/settings/notifications"
          component={NotificationsSettingsPage}
        />
        <Route
          exact
          path="/settings/disaster-relief"
          component={DisasterReliefPage}
        />
        <Route
          exact
          path="/settings/delete-account"
          component={DeleteAccountPage}
        />
        <Route exact path="/marketplace" component={MarketplacePage} />
        <Route exact path="/truck-rewards" component={TruckRewardsPage} />
        <Route exact path="/trucks" component={TrucksPage} />
        <Route exact path="/trucks/new" component={NewTruckPage} />
        <Route exact path="/trucks/:truckId/edit" component={EditTruckPage} />
        <Route exact path="/coupons" component={CouponsPage} />
        <Route exact path="/coupons/new" component={NewCouponPage} />
        <Route exact path="/coupons/:id" component={EditCouponPage} />
        <Route exact path="/users" component={UsersPage} />
        <Route exact path="/users/new" component={NewUserPage} />
        <Route exact path="/users/:userId/edit" component={EditUserPage} />
        <Route exact path="/account" component={AccountPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Route exact path="/refunds" component={OrderPartialRefundsListPage} />
        <Route exact path="/20-mile-apparel" component={TwentyMilePage} />
        <Route
          exact
          path="/refunds/:refundId"
          component={OrderPartialRefundPage}
        />
        <Route exact path="/orders/:orderId" component={OrderPage} />
        <Route exact path="/order-monitoring" component={OrderMonitoringPage} />
        <Route exact path="/trucks/:id/flyer" component={TruckFlyerPage} />
        <Route
          exact
          path="/trucks/:id/schedule-widget"
          component={ScheduleWidgetPage}
        />
        <Route
          exact
          path="/catering-requests"
          component={CateringRequestsPage}
        />
        <Route
          exact
          path="/catering-requests/:id/orders"
          component={CateringRequestOrdersPage}
        />
        <Route
          exact
          path="/catering-requests/:id"
          component={CateringRequestPage}
        />
        <Route exact path="/health-permits" component={HealthPermitsPage} />
        <Route exact path="/truck-permits" component={HealthPermitsPage} />
        <Route
          exact
          path="/health-permits/new"
          component={HealthPermitNewPage}
        />
        <Route exact path="/truck-permits/new" component={TruckPermitNewPage} />
        <Route
          exact
          path="/health-permits/:id"
          component={HealthPermitEditPage}
        />
        <Route
          exact
          path="/truck-permits/:id"
          component={TruckPermitEditPage}
        />
        <Route
          exact
          path="/locations/:id/stats"
          component={LocationStatsPage}
        />
        <Route
          exact
          path="/signup/permits"
          component={SignUpPermitsTruckPage}
        />
        <Route
          exact
          path="/signup/schedule"
          component={SignUpScheduleTruckPage}
        />
        <Route
          exact
          path="/chat-conversations"
          component={ChatConversationsPage}
        />
        <Route
          exact
          path="/chat-conversations/:id"
          component={ChatConversationPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
    ) : (
      <Switch>
        <Redirect exact to="/signup/truck" path="/signup" />
        <Route exact path="/signup/truck" component={SignUpTruckPage} />
        <Route
          exact
          path="/signup/reporting"
          component={SignUpReportingTruckPage}
        />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Route exact path="/marketplace" component={MarketplacePage} />
        <Route exact path="/claim-truck" component={ClaimTruckPage} />
        <Redirect to="/" path="/sign-in" />

        <Route
          render={(props) => {
            const redirectTo = props.location.pathname
            const query = queryString.parse(props.location.search)
            const newQuery = {
              ...query
            }
            if (
              redirectTo &&
              !redirectTo.includes("/login") &&
              redirectTo !== "/"
            ) {
              newQuery.redirectTo = redirectTo
            }
            return <Redirect to={`/login?${queryString.stringify(newQuery)}`} />
          }}
        />
      </Switch>
    )
    return (
      <main>
        <UserContext.Provider value={user}>
          <CssBaseline />
          <Navbar>
            <ErrorBoundary message="Error Displaying the page. Try reloading the browser.">
              {routes}
            </ErrorBoundary>
          </Navbar>
          <BlockUIConnected />
          <NotificationStack />
        </UserContext.Provider>
      </main>
    )
  }
}

App.propTypes = {
  user: PropTypes.object
}

export default compose(withRouter, connect(), withUser)(App)
