/**
 *
 * NotificationStack
 *
 */

import { compose } from "redux"
import injectReducer from "trucks/utils/injectReducer"
import {
  NotificationStack,
  reducer,
  mapStateToProps,
  mapDispatchToProps
} from "common/components/NotificationStack"
import { connect } from "react-redux"

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({
  key: "notificationStack",
  reducer
})

export default compose(withReducer, withConnect)(NotificationStack)
