/*
 *
 * Navbar reducer
 *
 */

import { fromJS } from "immutable"
import { CLOSE_SIDEBAR, OPEN_SIDEBAR } from "./constants"

export const initialState = fromJS({
  mobileOpen: false
})
function navbarReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return state.merge({ mobileOpen: true })
    case CLOSE_SIDEBAR:
      return state.merge({ mobileOpen: false })
    default:
      return state
  }
}

export default navbarReducer
